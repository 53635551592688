<template>
	<div>
		<!-- 提示 -->
		<a-modal title="" :visible="addTaskTips" :footer="null" @cancel="contiuneCreate" width="490px" centered
			:maskClosable="false">
			<div class="modaltips_container_add">
				<div class="modal_tips">
					<div class="img">
						<img src="../../assets/ok_icon.png" alt="" />
					</div>
					<div class="success_tip">任务创建成功</div>
				</div>
				<div class="modal_tips_test">
					<div>自由职业者可通过关注【智赋云数字经济】公众号,</div>
					<div>进入分时领英工作者端。</div>
				</div>
				<div class="btn_container">
					<a-button style="margin-right:30px" @click="contiuneCreate">继续创建任务</a-button>
					<a-button type="primary" @click="toDetail">查看任务详情</a-button>
				</div>
			</div>
		</a-modal>

		<div class="title">新建任务</div>

		<div class="new_add">
			<div class="add_container">
				<a-form style="margin-top: 27px" :form="form" :hideRequiredMark="true">
					<div class="basic_info">
						<div class="basic_title">基本信息</div>
						<div class="basic_form">
							<div class="form_item">
								<div class="type_item common_padd_left_13">
									<div class="item_label">任务类别：</div>
									<div class="item_content">
										<div class="content_container">
											<div class="item_every" v-for="item in taskTypeList" :key="item.id" :class="
                          item.id == taskTypeId ? 'item_every_active' : ''
                        " @click="taskTypeChoose(item)">
												{{ item.industryName }}
											</div>
										</div>
									</div>
								</div>
								<div class="type_item type_item_mar_t_14 common_padd_left_13" v-if="ticketContentList.length > 0">
									<div class="item_label" style="padding-bottom:0;">
										发票类目：
									</div>
									<div class="item_content">
										<span v-for="(item, index) in ticketContentList" :key="index"
											style="padding:3px;">{{ item.invoiceName }}、</span>
									</div>
								</div>

								<a-form-item>
									<div class="form_type_item common_padd_left_13 type_item_mar_t_27">
										<div class="item_title">任务名称：</div>
										<a-input placeholder="请输入任务名称(限30个字)" :maxLength="30" v-decorator="[
                        'title',
                        {
                          rules: [
                            { required: true, message: '请输入任务名称!' },
														{ min: 10, message: '长度不少于 10 个字符' },
                          ],
                        },
                      ]" />
									</div>
								</a-form-item>

								<!-- 预算类型 -->
								<!-- <div  class="two_type_box common_padd_left_13" >
                  <a-form-item >
                    <div class="form_type_item">
                      <div class="item_title">预算类型：</div>
                      <a-radio-group v-decorator="['budgetType', {rules: [{ required: true, message: '请选择预算类型', whitespace: true}]}]" style="width: 312px" @change="budgetTypeChange">
                        <a-radio value="10"> 面议</a-radio>
                        <a-radio value="20">金额</a-radio>
                      </a-radio-group>
                    </div>
                  </a-form-item>
              </div> -->

								<div class="two_type_box">
									<div class="yyje">
										<a-form-item v-if="budgetTypeChoose == '10'">
											<div class="form_type_item common_padd_left_13">
												<div class="item_title">
													预算金额：<span style="padding-left:15px">面议</span>
												</div>
											</div>
										</a-form-item>
										<a-form-item v-if="budgetTypeChoose == '20'">
											<div class="form_type_item common_padd_left_13">
												<div class="item_title">预算金额：</div>
												<a-input placeholder="起始金额" style="width:150px" :maxLength="30" suffix="元" type="number"
													v-decorator="[
                            'budgetStartAmount',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入预算起始金额',
                                },
                              ],
                            },
                          ]" />
											</div>
										</a-form-item>
										<div class="item_titleeee" v-if="budgetTypeChoose == '20'">
											~
										</div>
										<a-form-item v-if="budgetTypeChoose == '20'">
											<div class="form_type_item common_padd_left_13">
												<a-input placeholder="结束金额" style="width:150px" :maxLength="30" suffix="元" type="number"
													v-decorator="[
                            'budgetEndAmount',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入预算结束金额',
                                },
                              ],
                            },
                          ]" />
											</div>
										</a-form-item>
									</div>
									<a-form-item>
										<div class="form_type_item">
											<div class="item_title">预计人数：</div>
											<a-input placeholder="请输入预计人数" :maxLength="30" style="width:200px" suffix="人" type="number"
												v-decorator="[
                          'needNum',
                          {
                            rules: [
                              { required: true, message: '请输入预计人数' },
                            ],
                          },
                        ]" />
										</div>
									</a-form-item>
								</div>

								<div class="two_type_box">
									<a-form-item>
										<div class="form_type_item">
											<div class="item_title">任务起止日期：</div>
											<a-range-picker @change="rwTime" style="width:312px" v-decorator="[
                          'rwsj',
                          {
                            rules: [
                              {
                                type: 'array',
                                required: true,
                                message: '请选择任务起止日期!',
                              },
                            ],
                          },
                        ]" />
										</div>
									</a-form-item>
									<a-form-item>
										<div class="form_type_item">
											<div class="item_title">成果上传：</div>
											<a-radio-group v-decorator="[
                          'resultProvide',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请选择任务上传类型',
                                whitespace: true,
                              },
                            ],
                          },
                        ]" style="width: 312px">
												<a-radio value="10">
													由个人上传
												</a-radio>
												<a-radio value="20">
													由企业上传
												</a-radio>
											</a-radio-group>
										</div>
									</a-form-item>
								</div>

								<div class="two_type_box common_padd_left_13" v-if="taskIsShow">
									<a-form-item>
										<div class="form_type_item">
											<div class="item_title">招募模式：</div>
											<a-radio-group v-decorator="[
                          'isShow',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请选择任务上传类型',
                                whitespace: true,
                              },
                            ],
                          },
                        ]" style="width: 312px">
												<a-radio value="10">
													公开招募
												</a-radio>
												<a-radio value="20">
													非公开招募
												</a-radio>
											</a-radio-group>
										</div>
									</a-form-item>
								</div>

								<div class="two_type_box common_padd_left_13" v-if="taskIsIncludeFee">
									<a-form-item>
										<div class="form_type_item">
											<div class="item_title">服务费支付方式：</div>
											<a-radio-group v-decorator="[
                          'isIncludeFee',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请选择费用金额类型',
                                whitespace: true,
                              },
                            ],
                          },
                        ]" style="width: 512px">
												<a-radio value="10">
													由个人支付
												</a-radio>
												<a-radio value="20">
													由企业支付
												</a-radio>
											</a-radio-group>
										</div>
									</a-form-item>
								</div>
							</div>
						</div>
					</div>

					<div class="basic_info mar_top_30">
						<div class="basic_title">需求信息</div>
						<div class="desc_form">
							<div class="form_type  common_padd_left_13">
								<div class="type_title_desc">任务描述：</div>
								<div class="text_area">
									<a-form-item>
										<a-textarea placeholder="请输入任务描述" style="max-width: 734px; width: 730px"
											:autoSize="{ minRows: 10, maxRows: 20 }" v-decorator="[
                        'content',
                        {
                          rules: [
                            { required: true, message: '请输入任务描述' },
														{ min: 100, message: '长度不少于 100 个字符' },
                          ],
                        },
                      ]" />
									</a-form-item>
								</div>
							</div>
						</div>
					</div>

					<div class="done_btn">
						<a-button type="primary" @click="handleSubmit" :loading="loadingSubmit">确认发布</a-button>
					</div>
				</a-form>
			</div>
		</div>
	</div>
</template>
<script>
	import {
		getSysDictItem,
		raskListAdd,
		getRaskType,
		getRandomRecommendUser,
		checkRepetition,
		invitationRecommendUser,
		getUserByPhone,
		sendInvitationSms,
		getUserData,
		getInvoiceByIndustryId,
		batchInvitationRecommendUser,
		getFeePoint,
		queryImportWorkerList,
		submitApply,
		importWorkerStatistics,
		isWhiteList,
		getTaskType3,
		getTaskIsShow,
		getIsShowIsIncludeFee,
	} from "@/api/api";
	import moment from "moment";
	import Vue from "vue";
	import {
		ACCESS_TOKEN
	} from "@/store/mutation-types";
	import STable from "@/components/table/";
	export default {
		components: {
			STable,
		},
		data() {
			return {
				// 步骤条
				payMode: 20, //10单次结算  20多次结算
				resultMode: 10,
				step: 80,
				// 第一步
				formLayout: "horizontal",
				form: this.$form.createForm(this, {
					name: "coordinated"
				}),
				options: [],
				params: {
					taskType: "",
					enrollBeginDate: "",
					enrollEndDate: "",
					workBeginDate: "",
					workEndDate: "",
				},
				taskId: "",
				// 第二步
				inviteLists: [],
				visibleYq: false,
				confirmLoadingYq: false,
				yfje: 0,
				rwje: 0,
				inputYfje: 0,
				hasInvitor: false, // 是否查询到邀请用户
				visibleYg: false, //确认用工
				visibleYgMany: false, //确认用工多次
				confirmLoadingYg: false,
				changeLoading: false,
				currentItem: null,
				searchInviteUser: "",
				inviteUserPhone: "",
				stepTwoShowContentYq: false,
				tagsLists: [],
				intruductionLists: [],
				ticketContentList: [],
				userInfo: "",
				visiblePl: false,
				confirmLoadingPl: false,
				headers: {
					"X-Access-Token": Vue.ls.get(ACCESS_TOKEN)
				},
				columnsPl: [{
						title: "姓名",
						dataIndex: "userName",
						width: "100px",
					},
					{
						title: "电话",
						dataIndex: "phoneNo",
						width: "150px",
					},
					{
						title: "预付金额",
						dataIndex: "advanceAmount",
						customRender: (text) =>
							text === null ? "--" : (Math.floor(text * 100) / 100).toFixed(2),
						width: "100px",
					},
					{
						title: "尾款金额",
						dataIndex: "balanceAmount",
						customRender: (text) =>
							text === null ? "--" : (Math.floor(text * 100) / 100).toFixed(2),
						width: "100px",
					},
					{
						title: "注册状态",
						dataIndex: "loginStatus",
						customRender: (text) => (text === 1 ? "已注册" : "未注册"),
						width: "100px",
					},
					{
						title: "操作",
						dataIndex: "action",
						width: "100px",
						scopedSlots: {
							customRender: "action"
						},
					},
				],
				dataPl: [],
				importData: {
					taskId: this.taskId,
				},
				url: window._CONFIG["domianURL"] + "/slash-youth/client/b/task/importWorker",
				pagenation: {
					pageSize: 30000,
					hideOnSinglePage: true,
				},
				uploadInfo: [],
				taxPoint: "",
				batchId: "",
				allowSubmit: false,
				tableInfo: {
					taskAmount: 0.0,
					advanceAmount: 0.0,
					balanceAmount: 0.0,
					feeAmount: 0.0,
					payAmount: 0.0,
					countNum: 0.0,
				},
				submitDetail: "",
				visibleYqMany: false,
				isWhite: false,
				labelCol: {
					xs: {
						span: 24
					},
					sm: {
						span: 5
					},
				},
				wrapperCol: {
					xs: {
						span: 24
					},
					sm: {
						span: 12
					},
				},
				taskTypeList: [],
				taskTypeId: "",
				addTaskTips: false,
				taskIsShow: false,
				loadingSubmit: false,

				taskIsIncludeFee: false,
				budgetTypeChoose: "20",
				sensitiveWord: [], //敏感词
			};
		},
		async created() {
			this.getTaskIsShow();
			await getSysDictItem({
				dictType: "task_sensitive_words"
			}).then((res) => {
				if (res.success) this.sensitiveWord = res.result;
			});
			// this.getTags();
			// this.getIntruduction();
			this.form.resetFields();
			// this.getRaskType();
			// this.checkWhite();
			this.params = {
				taskType: "",
				enrollBeginDate: "",
				enrollEndDate: "",
				workBeginDate: "",
				workEndDate: "",
			};

			// this.getRandomRecommendUser();
			// this.getPoint();
			this.getTaskTypeNew();
			this.getIsShowIsIncludeFee();
		},
		mounted() {
			this.$nextTick(() => {
				this.form.setFieldsValue({
					resultProvide: "20",
					budgetType: this.budgetTypeChoose,
				});
			});
		},
		computed: {
			// 费用
			fy() {
				if (this.rwje) {
					return (this.rwje * this.taxPoint).toFixed(2);
				} else {
					return 0.0;
				}
			},
			// 总金额
			allFys() {
				if (this.rwje) {
					return (this.rwje * this.taxPoint + Number(this.rwje)).toFixed(2);
				} else {
					return 0.0;
				}
			},
			// 尾款
			wk() {
				if (this.inputYfje && this.rwje) {
					return (this.rwje - this.inputYfje).toFixed(2);
				} else {
					return 0.0;
				}
			},
		},
		methods: {
			budgetTypeChange(e) {
				console.log(e);
				this.budgetTypeChoose = e.target.value;
			},
			getIsShowIsIncludeFee() {
				getIsShowIsIncludeFee().then((res) => {
					if (res.success) {
						this.taskIsIncludeFee = res.result;
						if (this.taskIsShow) {
							setTimeout(() => {
								this.form.setFieldsValue({
									isIncludeFee: "10",
								});
							}, 50);
						}
					}
				});
			},
			getTaskIsShow() {
				getTaskIsShow().then((res) => {
					if (res.success) {
						this.taskIsShow = res.result;
						if (this.taskIsShow) {
							setTimeout(() => {
								this.form.setFieldsValue({
									isShow: "10",
								});
							}, 50);
						}
					}
				});
			},
			taskTypeChoose(item) {
				console.log(item);
				this.taskTypeId = item.id;
				this.taskTypeName = item.industryName;
				getInvoiceByIndustryId(item.id).then((res) => {
					if (res.success) {
						this.ticketContentList = res.result;
					}
				});
			},
			//获取任务类别
			getTaskTypeNew() {
				getTaskType3().then((res) => {
					if (res.success) {
						this.taskTypeList = res.result;
					}
				});
			},
			// 支付方式
			checkPayMode(mode) {
				this.payMode = mode;
			},
			// 上传类型
			checkResultMode(mode) {
				this.payMode = mode;
			},
			// 查询白名单
			checkWhite() {
				isWhiteList().then((res) => {
					if (res.success) {
						this.isWhite = res.result;
					}
				});
			},
			// 查询批量上传工作者列表
			raskData(parameter) {
				return queryImportWorkerList(
					Object.assign(parameter, {
						batchId: this.batchId
					})
				).then((res) => {
					this.dataPl = res.result.data;
					this.allowSubmit = res.result.data.length === 0 ? false : true;
					return res.result;
				});
			},
			// 获取费率
			getPoint() {
				getFeePoint().then((res) => {
					this.taxPoint = res.result;
				});
			},
			// 第一步
			validateInputCode(rule, value, callback) {
				let s = Number(value);
				if (!isNaN(s)) {
					callback();
				} else {
					callback("请输入大于零的数!");
				}
			},
			// 获取任务类型
			getRaskType() {
				getRaskType().then((res) => {
					if (res.success) {
						this.options = res.result;
						this.options.forEach((item) => {
							item.label = item.industryName;
							item.value = item.id;
							if (item.children.length > 0) {
								item.children.forEach((list) => {
									list.label = list.industryName;
									list.value = list.id;
									list.children = "";
								});
							}
						});
					}
				});
			},
			onChange(value) {
				console.log(value);
				this.params.taskType = value[1];
				this.params.taskTypeName = value[0];
				getInvoiceByIndustryId(value[value.length - 1]).then((res) => {
					if (res.success) {
						this.ticketContentList = res.result;
					}
				});
			},
			// 报名时间
			bmTime(date, dateString) {
				this.params.enrollBeginDate = dateString[0];
				this.params.enrollEndDate = dateString[1];
			},
			// 任务时间
			rwTime(date, dateString) {
				this.params.workBeginDate = dateString[0];
				this.params.workEndDate = dateString[1];
			},
			// 提交
			handleSubmit(e) {
				if (!this.taskTypeId) {
					this.$message.info("请选择任务类型");
					return;
				}
				// e.preventDefault();
				this.form.validateFields(async (err, values) => {
					if (!err) {
						// 查询敏感词
						let result = ""; //敏感词匹配结果
						for (const element of this.sensitiveWord) {
							if (values.title.indexOf(element) > -1) {
								result = "任务名称内不能包含非法字符【" + element + "】";
								break;
							}
							if (values.content.indexOf(element) > -1) {
								result = '任务描述内不能包含非法字符【"' + element + "】";
								break;
							}
						}
						if (result) {
							this.$message.error(result);
							return;
						}
						// console.log('Received values of form: ', values);
						let params = Object.assign(values, this.params);
						params.payMode = '20';
						params.enrollBeginDate = params.workBeginDate;
						params.enrollEndDate = params.workEndDate;
						params.budgetType = this.budgetTypeChoose;
						// if (moment(params.workBeginDate).isBefore(params.enrollEndDate)) {
						//   this.$message.error('任务开始时间需大于报名结束时间')
						//   return
						// }
						//  console.log('====================================');
						// console.log('====================================');
						if (params.budgetType == "20") {
							if (params.budgetStartAmount < 0 || params.budgetStartAmount == 0) {
								this.$message.error("预算起始金额须大于0");
								return;
							}
							if (params.budgetEndAmount < 0 || params.budgetEndAmount == 0) {
								this.$message.error("预算结束金额须大于0");
								return;
							}
							if (
								Number(params.budgetStartAmount) > Number(params.budgetEndAmount)
							) {
								this.$message.error("预算起始金额应小于预算结束金额");
								return;
							}
							if (params.budgetEndAmount - params.budgetStartAmount > 10000) {
								this.$message.error("预算金额区间不能超过 10000 元");
								return;
							}
							if (params.needNum < 0 || params.needNum == 0) {
								this.$message.error("预计人数须大于0");
								return;
							}
						} else {
							params.budgetStartAmount = "0";
							params.budgetEndAmount = "0";
						}

						params.budgetStartAmount = Number(params.budgetStartAmount).toFixed(
							2
						);
						params.budgetEndAmount = Number(params.budgetEndAmount).toFixed(2);
						params.taskType = this.taskTypeId;
						params.taskTypeName = this.taskTypeName;

						// console.log('====================================');
						// console.log(params);
						// console.log('====================================');

						// return
						this.loadingSubmit = true;
						raskListAdd(params)
							.then((res) => {
								this.loadingSubmit = false;
								if (res.success) {
									this.taskId = res.result.taskId;
									this.importData.taskId = res.result.taskId;

									this.form.resetFields();
									// this.getRandomRecommendUser();
									this.submitDetail = params;
									// this.step = 2;
									this.budgetTypeChoose = "10";
									this.form.setFieldsValue({
										resultProvide: "10",
										budgetType: this.budgetTypeChoose,
									});
									this.addTaskTips = true;
								} else {
									this.$message.error(res.message);
								}
							})
							.finally(() => {
								this.loadingSubmit = false;
							});
					}
				});
			},
			// 下拉选择
			handleSelectChange(value) {
				console.log(value);
				this.form.setFieldsValue({
					note: `Hi, ${value === "male" ? "man" : "lady"}!`,
				});
			},
			// 取消
			cancelAdd() {
				window.localStorage.setItem("submenu", "raskManage");
				this.$router.push("/rask");
			},
			// 第二步
			nextTwo() {
				this.step = 2;
			},
			tofixed() {
				this.inputYfje = Number(this.inputYfje).toFixed(2);
			},
			// 获取行业标签
			getTags() {
				getUserData({
					code: "person_tags"
				}).then((res) => {
					if (res.success) {
						this.tagsLists = res.result;
					}
				});
			},
			// 获取行业标签
			getIntruduction() {
				getUserData({
					code: "person_intruduction"
				}).then((res) => {
					if (res.success) {
						this.intruductionLists = res.result;
					}
				});
			},
			// 获取优质人才
			getRandomRecommendUser() {
				getRandomRecommendUser().then((res) => {
					if (res.success) {
						this.inviteLists = res.result;
						this.inviteLists.forEach((item) => {
							let times = Math.ceil(Math.random() * 10);
							let workYears = Math.ceil(Math.random() * 10);
							let tags = Math.floor(Math.random() * this.tagsLists.length);
							let intr = Math.floor(
								Math.random() * this.intruductionLists.length
							);
							item.hasInvite = false;
							item.times = times;
							item.workYears = workYears;
							item.tags = this.tagsLists[tags];
							item.intr = this.intruductionLists[intr];
						});
					}
				});
			},
			// 换一批
			changeOne() {
				this.changeLoading = true;
				this.getRandomRecommendUser();
				this.changeLoading = false;
			},
			// 邀请人员
			inviteUser() {
				this.visibleYq = true;
			},
			// 邀请人员(多次)
			inviteUserMany() {
				this.visibleYqMany = true;
			},
			// 搜索
			onSearchInvite(value) {
				if (/^(?:(?:\+|00)86)?1[3-9]\d{9}$/.test(this.inviteUserPhone)) {
					getUserByPhone(this.inviteUserPhone).then((res) => {
						if (res.result.length > 0 && res.success) {
							this.currentItem = res.result[0];
							this.hasInvitor = true;
						} else {
							this.hasInvitor = false;
							this.stepTwoShowContentYq = true;
						}
					});
				} else {
					this.$message.error("手机号格式不正确");
				}
			},
			// 任务金额
			onChangeRwje() {
				if (this.rwje !== 0) {
					this.inputYfje = (this.rwje * this.yfje).toFixed(2);
				}
			},
			// 单选预付金额
			onChangeYfje() {
				console.log("radio checked", this.yfje);
				if (this.yfje !== 0) {
					this.inputYfje = (this.yfje * this.rwje).toFixed(2);
				}
			},
			// 发送短信
			sendDx() {
				if (/^(?:(?:\+|00)86)?1[3-9]\d{9}$/.test(this.inviteUserPhone)) {
					sendInvitationSms(this.inviteUserPhone).then((res) => {
						if (res.success) {
							this.$message.success(res.message);
							this.handleCancelYq();
						} else {
							this.$message.error(res.message);
						}
					});
				} else {
					this.$message.error("手机号格式不正确");
				}
			},
			// 确认发送
			handleOkYq() {
				if (this.submitDetail.payMode === "10") {
					if (this.rwje === 0 || this.rwje === "0") {
						this.$message.error("请输入任务金额");
						return;
					}
					if (this.inputYfje && this.inputYfje < 0) {
						this.$message.error("请输入预付金额");
						return;
					}
					if (Number(this.inputYfje) > Number(this.rwje)) {
						this.$message.error("预算金额不能大于任务金额");
						return;
					}
					this.confirmLoadingYq = true;
					invitationRecommendUser(
						Object.assign(this.currentItem, {
							taskId: this.taskId,
							advanceAmount: this.inputYfje,
							balanceAmount: this.wk,
						})
					).then((res) => {
						this.confirmLoadingYq = false;
						if (res.success) {
							this.$message.success(res.message);
							this.handleCancelYq();
						} else {
							this.$message.error(res.message);
						}
					});
				} else {
					this.confirmLoadingYq = true;
					invitationRecommendUser(
						Object.assign(this.currentItem, {
							taskId: this.taskId
						})
					).then((res) => {
						this.confirmLoadingYq = false;
						if (res.success) {
							this.$message.success(res.message);
							this.handleCancelYqMany();
						} else {
							this.$message.error(res.message);
						}
					});
				}
			},
			// 取消邀请
			handleCancelYq() {
				this.visibleYq = false;
				this.hasInvitor = false;
				this.inviteUserPhone = "";
				this.rwje = 0;
				this.inputYfje = 0;
				this.yfje = 0;
				this.stepTwoShowContentYq = false;
			},
			// 取消邀请
			handleCancelYqMany() {
				this.visibleYqMany = false;
				this.hasInvitor = false;
				this.stepTwoShowContentYq = false;
			},
			// 确认用工
			qryg(item) {
				this.currentItem = item;
				checkRepetition({
					taskId: this.taskId,
					userId: item.userId
				}).then(
					(res) => {
						if (!res.success) {
							this.$message.warning("该用户已用工");
							this.inviteLists.forEach((list) => {
								if (list.userId === this.currentItem.userId) {
									list.hasInvite = true;
								}
							});
							let ss = this.inviteLists;
							this.inviteLists = [];
							this.inviteLists = ss;
						} else {
							this.visibleYg = true;
						}
					}
				);
			},
			// 确认用工多次
			qrygMany(item) {
				this.currentItem = item;
				checkRepetition({
					taskId: this.taskId,
					userId: item.userId
				}).then(
					(res) => {
						if (!res.success) {
							this.$message.warning("该用户已用工");
							this.inviteLists.forEach((list) => {
								if (list.userId === this.currentItem.userId) {
									list.hasInvite = true;
								}
							});
							let ss = this.inviteLists;
							this.inviteLists = [];
							this.inviteLists = ss;
						} else {
							this.visibleYgMany = true;
						}
					}
				);
			},
			handleOkYg() {
				if (this.submitDetail.payMode === "10") {
					if (this.rwje === 0 || this.rwje === "0") {
						this.$message.error("请输入任务金额");
						return;
					}
					if (this.inputYfje && this.inputYfje < 0) {
						this.$message.error("请输入预付金额");
						return;
					}
					if (Number(this.inputYfje) > Number(this.rwje)) {
						this.$message.error("预算金额不能大于任务金额");
						return;
					}
					this.confirmLoadingYg = true;
					invitationRecommendUser(
						Object.assign(this.currentItem, {
							taskId: this.taskId,
							advanceAmount: this.inputYfje,
							balanceAmount: this.wk,
						})
					).then((res) => {
						this.confirmLoadingYg = false;
						if (res.success) {
							this.$message.success(res.message);
							this.inviteLists.forEach((item) => {
								if (item.userId === this.currentItem.userId) {
									item.hasInvite = true;
								}
							});
							this.handleCancelYg();
						} else {
							this.$message.error(res.message);
						}
					});
				} else {
					this.confirmLoadingYg = true;
					invitationRecommendUser(
						Object.assign(this.currentItem, {
							taskId: this.taskId
						})
					).then((res) => {
						this.confirmLoadingYg = false;
						if (res.success) {
							this.$message.success(res.message);
							this.inviteLists.forEach((item) => {
								if (item.userId === this.currentItem.userId) {
									item.hasInvite = true;
								}
							});
							this.handleCancelYgMany();
						} else {
							this.$message.error(res.message);
						}
					});
				}
			},
			// 取消用工
			handleCancelYg() {
				this.visibleYg = false;
				this.rwje = 0;
				this.inputYfje = 0;
				this.yfje = 0;
			},
			// 取消用工多次
			handleCancelYgMany() {
				this.visibleYgMany = false;
			},
			// 批量邀请
			inviteUsers() {
				this.uploadInfo = [];
				this.visiblePl = true;
			},
			// 汇总批量上传表格人数、金额
			importWorkerStatistics() {
				importWorkerStatistics(this.batchId).then((res) => {
					if (res.success) {
						this.tableInfo = res.result;
					}
				});
			},
			// 上传
			handleChange(info) {
				if (info.fileList.length > 1) {
					info.fileList.shift();
				}
				if (info.file.status === "removed" && info.fileList.length === 0) {
					this.batchId = "";
					this.$refs.raskTable.refresh(true);
					this.tableInfo = {
						taskAmount: 0.0,
						advanceAmount: 0.0,
						balanceAmount: 0.0,
						feeAmount: 0.0,
						payAmount: 0.0,
						countNum: 0.0,
					};
				}
				// console.log(info,info.file, info.fileList);
				this.uploadInfo = info.fileList;
				if (info.file.status !== "uploading") {}
				if (info.file.status === "done") {
					if (info.file.response.success) {
						this.batchId = info.file.response.result.batchId;
						this.$message.success(info.file.response.message);
						this.$refs.raskTable.refresh(true);
						this.importWorkerStatistics();
					} else {
						this.$message.error(info.file.response.message);
					}
				}
			},
			handleOkPl() {
				let params = {
					taskId: this.taskId,
					batchId: this.batchId,
				};
				this.confirmLoadingPl = true;
				submitApply(params).then((res) => {
					this.confirmLoadingPl = false;
					if (res.success) {
						this.$message.success(res.message);
						this.handleCancelPl();
					} else {
						this.$message.error(res.message);
					}
				});
			},
			handleCancelPl() {
				this.visiblePl = false;
				this.batchId = "";
				this.$refs.raskTable.refresh(true);
				this.tableInfo = {
					taskAmount: 0.0,
					advanceAmount: 0.0,
					balanceAmount: 0.0,
					feeAmount: 0.0,
					payAmount: 0.0,
					countNum: 0.0,
				};
			},
			// 发送短信
			sendMsg(record) {
				sendInvitationSms(record.phoneNo).then((res) => {
					if (res.success) {
						this.$message.success(res.message);
					} else {
						this.$message.error(res.message);
					}
				});
			},
			// 第三步
			contiuneCreate() {
				this.addTaskTips = false;
				this.loadingSubmit = false;
			},
			// 详情
			toDetail() {
				this.addTaskTips = false;
				window.localStorage.setItem("raskId", this.taskId);
				if (this.submitDetail.payMode == "10") {
					window.localStorage.setItem("submenu", "/raskDetailNew");
					this.$router.push("/raskDetailNew");
				}
				if (this.submitDetail.payMode == "20") {
					window.localStorage.setItem("submenu", "/raskDetailManyNew");
					this.$router.push("/raskDetailManyNew");
				}
			},
		},
	};
</script>
<style scoped lang="less">
	.yyje {
		display: flex;
		align-items: center;

		.item_titleeee {
			height: 40px;
			padding: 0 0 0 20px;
		}
	}

	.modaltips_container_add {
		text-align: center;

		.modal_tips {
			.success_tip {
				font-size: 24px;
				color: #000000;
				padding-bottom: 15px;
				padding-top: 11px;
			}
		}

		.modal_tips_test {
			font-size: 14px;
			color: #707882;
			padding-bottom: 30px;
		}
	}

	.done_btn {
		width: 100%;
		text-align: center;
		padding-top: 43px;
	}

	.pay_mode {
		display: flex;

		.mode_title {
			color: #4d4d4d;
			font-size: 14px;
			white-space: nowrap;
			padding-right: 13px;
		}

		.mode_select {
			width: 100%;

			.upload_container1 {
				display: flex;

				.check_item {
					cursor: pointer;
					width: 267px;
					height: 124px;
					border: 1px solid #d9d9d9;
					box-sizing: border-box;
					border-radius: 4px;
					margin-right: 18px;

					.item_container {
						padding: 25px 29px 25px 25px;
						color: #666;

						.upload_title {
							font-size: 20px;
							font-weight: bold;
							padding-bottom: 10px;
						}

						.upload_subtitle {
							font-size: 12px;
						}
					}
				}

				.checke_item_upload_active {
					border: 1px solid #1890ff;
					background: url("../../assets/check_img.png") no-repeat 203px -1px;

					.upload_title {
						color: #1890ff;
					}
				}
			}
		}
	}

	.type_item_mar_t_27 {
		margin-top: 27px;
	}

	.type_item_mar_t_14 {
		margin-top: 14px;
	}

	.mar_top_30 {
		margin-top: 30px;
	}

	.common_padd_left_13 {
		padding-left: 27px;
	}

	.new_add {
		width: 100%;

		.add_container {
			width: 900px;
			margin: 0 auto;

			.basic_info {
				width: 100%;
				box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);

				.basic_title {
					height: 50px;
					border-bottom: 1px solid #efefef;
					padding-left: 24px;
					color: #000000;
					font-size: 18px;
					line-height: 50px;
				}

				.basic_form {
					padding: 35px 46px 35px 19px;
					box-sizing: border-box;

					.form_item {
						.type_item {
							display: flex;
							align-items: center;

							.item_label {
								color: #4d4d4d;
								font-size: 14px;
								white-space: nowrap;
								padding-right: 13px;
								padding-bottom: 14px;
							}

							.item_content {
								.content_container {
									display: flex;
									flex-wrap: wrap;
									align-items: center;

									.item_every {
										padding: 6px 11px;
										color: #606266;
										border: 1px solid #dcdfe6;
										margin-right: 14px;
										cursor: pointer;
										margin-bottom: 14px;
										border-radius: 2px;
									}

									.item_every_active {
										border-color: #1890ff;
									}
								}
							}
						}

						.form_type_item {
							display: flex;
							align-items: center;

							.item_title {
								white-space: nowrap;
								padding-right: 13px;
							}
						}

						.two_type_box {
							display: flex;
							align-items: center;
							justify-content: space-between;

							.form_type_item {}
						}
					}
				}

				.desc_form {
					padding: 35px 46px 35px 19px;
					box-sizing: border-box;

					.form_type {
						display: flex;

						.type_title_desc {
							padding-right: 13px;
							white-space: nowrap;
						}
					}
				}
			}
		}
	}

	.add_tips {
		position: absolute;
		left: 550px;
		top: 50px;
		// min-wdith: 200px;
		//
		width: 200px;
		height: 90px;
		z-index: 9999;

		.tips_container {
			.tips_box {
				padding: 18px 12px 7px 15px;
				background: #1890ff;
				border-radius: 5px;
				color: #fff;
				position: relative;

				&::after {
					content: "";
					position: absolute;
					left: -9px;
					top: 9px;
					width: 0;
					height: 0;
					border-top: 10px solid transparent;
					border-right: 10px solid #1890ff;
					border-bottom: 10px solid transparent;
				}

				.tips_text1,
				.tips_text2 {
					font-size: 12px;
				}

				.tips_text2 {
					text-align: right;
					padding-top: 10px;
					cursor: pointer;
					opacity: 0;
				}
			}
		}
	}

	.done_btns {
		display: flex;
		justify-content: center;
	}

	.type_title {
		font-size: 20px;
		color: #262626;
		padding-bottom: 20px;
	}

	.step_01 {
		width: 100%;
		padding: 30px 20px 70px 108px;

		.step01_container {
			width: 100%;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;

			.settlement_type {
				width: 700px;

				.check_container {
					display: flex;
					justify-content: space-between;

					.check_item {
						width: 337px;
						height: 496px;
						background: #ffffff;
						border: 1px solid #d9d9d9;
						box-sizing: border-box;
						border-radius: 4px;
						padding: 25px 19px 0 22px;
						cursor: pointer;

						.check_item_title {
							font-size: 20px;
							font-weight: bold;
							color: #666666;
							padding-bottom: 12px;
						}

						.check_item_desc {
							font-size: 12px;
							color: #666;
							padding-bottom: 20px;
						}

						.check_item_subtitle {
							font-size: 14px;
							color: #262626;
							padding-bottom: 8px;
						}

						.check_item_subdesc {
							font-size: 12px;
							color: #666;
							padding-bottom: 8px;
						}

						.process_img {}

						.process {
							display: flex;

							.process_title {
								font-size: 14px;
								color: #262626;
								padding-left: 10px;
							}

							.process_subtitle {
								font-size: 12px;
								color: #666666;
								padding-left: 10px;
							}

							.process_step {
								display: flex;
								flex-direction: column;
								justify-content: center;
								align-items: center;
								padding-left: 3px;
								margin-right: 5px;

								.line {
									width: 1px;
									height: 25px;
									background: #fba85b;
								}

								.line_1 {
									height: 54px;
								}

								.line_2 {
									height: 57px;
								}

								.circle {
									width: 22px;
									height: 22px;
									border-radius: 50%;
									background: #fba85b;
									color: #fff;
									text-align: center;
									line-height: 22px;
									z-index: 999;
								}
							}

							.process_desc {
								width: 100%;
								position: relative;
								top: 5px;

								.process_desc_item {
									width: 100%;
									height: 49px;
									padding-top: 14px;
									// border: 1px solid #ECEDEA;
									padding-left: 10px;
									border-radius: 4px;
									box-sizing: border-box;
									margin-bottom: 10px;
									// margin-top: 7px;
									position: relative;
									top: 10px;
									background: url("../../assets/sjx.png") no-repeat 0 0;
									background-size: 100% 100%;
								}

								.process_desc_item_2 {
									width: 100%;
									height: 79px;
									padding-top: 8px;
									// border: 1px solid #ECEDEA;
									padding-left: 10px;
									border-radius: 4px;
									box-sizing: border-box;
									margin-bottom: 10px;
									position: relative;
									top: 10px;
									background: url("../../assets/sjx.png") no-repeat 0 0;
									background-size: 100% 100%;
								}

								.process_desc_item_3 {
									width: 100%;
									height: 59px;
									padding-top: 8px;
									// border: 1px solid #ECEDEA;
									padding-left: 10px;
									border-radius: 4px;
									box-sizing: border-box;
									position: relative;
									top: 10px;
									background: url("../../assets/sjx.png") no-repeat 0 0;
									background-size: 100% 100%;
								}
							}
						}
					}

					.checke_item_active {
						border: 1px solid #1890ff;
						background: url("../../assets/check_img.png") no-repeat 273px -1px;

						.check_item_title {
							color: #1890ff;
						}
					}
				}
			}

			.upload_type {
				width: 560px;

				.upload_container {
					display: flex;
					justify-content: space-between;

					.check_item {
						cursor: pointer;
						width: 267px;
						height: 124px;
						border: 1px solid #d9d9d9;
						box-sizing: border-box;
						border-radius: 4px;

						.item_container {
							padding: 25px 29px 25px 25px;
							color: #666;

							.upload_title {
								font-size: 20px;
								font-weight: bold;
								padding-bottom: 10px;
							}

							.upload_subtitle {
								font-size: 12px;
							}
						}
					}

					.checke_item_upload_active {
						border: 1px solid #1890ff;
						background: url("../../assets/check_img.png") no-repeat 203px -1px;

						.upload_title {
							color: #1890ff;
						}
					}
				}
			}
		}

		.next_btn {
			margin-top: 70px;
			width: 100%;
			text-align: center;
		}
	}

	@media screen and (max-width: 1400px) {
		.step_01 {
			.step01_container {}
		}
	}

	.title {
		font-size: 24px;
		color: #202020;
		margin: 0 0 20px 0px;
	}

	.step {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		.left {
			width: 1000px;
		}

		.right {
			width: 500px;
			position: relative;

			.ant-form {
				.ant-form-item {
					display: flex;
				}
			}
		}
	}

	.step-02 {
		width: 100%;
		padding: 40px 44px 40px 27px;

		.header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding-right: 35px;

			span {
				margin-left: 17px;
				font-size: 22px;
				color: #000000;
			}
		}

		.content {
			padding: 0;
			list-style: none;
			display: flex;
			flex-wrap: wrap;

			li {
				margin: 17px 0 0 17px;
				width: 450px;
				background: #ffffff;
				border: 1px solid #e1e4e8;
				border-radius: 3px;

				.construction {
					padding: 16px;
					border-bottom: 1px solid #e1e4e8;
					display: flex;

					.c-left {
						width: 64px;
						height: 64px;
						text-align: center;
						line-height: 64px;
						font-size: 26px;
						background: #dddddd;
						border-radius: 50%;
						margin-right: 13px;
					}

					.c-right {
						width: 300px;

						.cr-title {
							width: 100%;
							display: flex;
							justify-content: space-between;
							align-items: center;

							.name {
								font-size: 20px;
								color: rgba(0, 0, 0, 0.85);
								margin-right: 8px;
							}

							.phone {
								font-size: 14px;
								color: rgba(0, 0, 0, 0.65);
							}

							.hzcs {
								color: #2878ff;
								font-size: 14px;
							}
						}

						.desc {
							width: 100%;
							text-align: justify;
							font-size: 14px;
							color: rgba(0, 0, 0, 0.85);
							margin: 5px 0 10px 0;
							height: 42px;
							overflow: hidden;
							text-overflow: ellipsis;
							display: -webkit-box;
							-webkit-line-clamp: 2;
							-webkit-box-orient: vertical;
						}
					}
				}

				.footer {
					width: 100%;
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding: 10px 16px;
					font-size: 12px;
					color: rgba(0, 0, 0, 0.85);

					span {
						margin-left: 8px;
						padding: 3px 10px;
						background: #ebf0f8;
						border-radius: 10px;
						text-align: center;
						line-height: 20px;
					}
				}
			}
		}

		.stept-opt {
			margin: 20px 0 0 17px;
			width: 100%;
			text-align: center;
		}
	}

	.step-03 {
		width: 100%;
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		padding: 70px 0 359px 0;

		.step3-head {
			font-size: 24px;
			font-weight: bold;
			color: #000000;
			margin: 30px 0 19px 0;
		}

		.step3-desc {
			color: rgba(0, 0, 0, 0.65);
			font-size: 14px;
			margin: 0 0 29px 0;
		}
	}

	.fsyq-head {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		.fsyq-title {
			font-size: 14px;
			color: rgba(32, 32, 32, 0.65);
			margin: 0 0 14px 0;
		}

		.user-info {
			margin: 24px 0;

			.img {
				width: 100px;
				height: 100px;
				border-radius: 3px;
				margin-bottom: 8px;
				text-align: center;
				line-height: 100px;
				font-size: 40px;
				background: #dddddd;
				border-radius: 50%;
			}

			div {
				text-align: center;
				color: rgba(32, 32, 32, 0.65);
				font-size: 14px;
			}
		}
	}

	.common-content {
		.cc-title {
			padding: 18px 0;
			border-bottom: 1px solid #f0f0f0;
			font-size: 18px;
			color: #000000;
		}

		.cc-desc {
			font-size: 14px;
			color: rgba(32, 32, 32, 0.65);
			margin: 24px 0 0 0;
		}

		.cc-content {
			display: flex;
			align-items: center;
			align-items: center;
			font-size: 16px;
			color: #333333;
			margin: 24px 0 0 0;
		}

		.cc-footer {
			margin: 24px 0;
			padding: 20px 80px;
			width: 640px;
			// height: 95px;
			background: #fafafa;
			border-radius: 3px 0px 0px 0px;
			display: flex;
			align-items: center;
			justify-content: center;

			.ccf-left,
			.ccf-right {
				color: #333333;
				font-size: 16px;

				span {
					font-size: 24px;
					color: #ff4d4f;
				}

				div {
					&:last-child {
						margin: 10px 0 0 0;
						font-size: 14px;
						color: rgba(32, 32, 32, 0.65);
					}
				}
			}

			.ccf-right {
				margin-left: 100px;
			}
		}
	}

	.common-opt {
		text-align: center;
		margin: 20px 0 20px 0;
	}

	.no-invite {
		margin: 17px 0 77px 0;
		text-align: center;
		font-size: 14px;
		color: rgba(32, 32, 32, 0.65);
	}

	.pl-table {
		border: 1px solid #dadada;
		border-radius: 6px;
		margin: 15px 0 0 0;
	}

	.pl-total {
		margin: 10px 0 30px 0;
		display: flex;
		flex-wrap: wrap;
		align-items: center;

		.total-ze {
			color: #33383e;
			font-size: 16px;
			margin: 15px 15px 0 0;

			.total-zee {
				color: #ff4d4f;
				font-size: 24px;
			}

			.total-zeee {
				color: #ff4d4f;
				font-size: 16px;
			}
		}
	}

	.xzmb {
		position: absolute;
		top: 5px;
		left: 100px;
	}
</style>